/** font family **/
@media only screen and (min-width: 768px) {
  /*! "$not-mobile" */
}
@media only screen and (min-width: 901px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (min-width: 1025px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (max-width: 1599px) {
  /*! "until-dt-max" */
}
@media only screen and (max-width: 1366px) {
  /*! "until-dt-med" */
}
@media only screen and (max-width: 1279px) {
  /*! "until-dt-min" */
}
@media only screen and (min-width: 1280px) {
  /*! "dt-min" */
}
@media only screen and (max-width: 1024px) {
  /*! "tablet-max" */
}
@media only screen and (min-width: 901px) and (max-width: 1024px) {
  /*! "tablet-ls" */
}
@media only screen and (max-width: 900px) {
  /*! "mobile-tablet-pt" */
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  /*! "tablet-pt" */
}
@media only screen and (max-width: 767px) {
  /*! "$mobile-max" */
}
@media only screen and (max-width: 415px) {
  /*! "$mobile-pt" */
}
.banner {
  --date-color: #2d2d2d;
  position: relative;
}
.banner .countdownLocalized__countdown-wrap {
  background-color: #7adec9;
  margin-bottom: 15px;
  padding: 10px;
  position: relative;
}
@media only screen and (min-width: 901px) {
  .banner .countdownLocalized__countdown-wrap {
    margin-bottom: 20px;
  }
}
.banner .countdownLocalized__value,
.banner .countdownLocalized__label {
  color: var(--date-color);
}
.banner .countdownLocalized__label::after {
  background-color: var(--date-color);
}
.banner .countdownLocalized__icon::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: inherit;
  background-image: none;
  color: var(--date-color);
  content: "\f10f";
  font-size: 15px;
  line-height: 1;
}
@media only screen and (min-width: 901px) {
  .banner .countdownLocalized__icon::before {
    font-size: 20px;
  }
}
.banner:not(.banner--no-lineplay)::after {
  background-image: url("../../../../img/svg/lineplay-banner.svg");
  background-position: right top;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: -650px;
  content: "";
  height: 667px;
  position: absolute;
  right: calc(50% - 685px);
  width: 1920px;
  z-index: -1;
}
@media only screen and (max-width: 415px) {
  .banner:not(.banner--no-lineplay)::after {
    bottom: -700px;
    right: calc(50% - 556px);
  }
}
@media only screen and (min-width: 901px) {
  .banner:not(.banner--no-lineplay)::after {
    bottom: -590px;
    height: 950px;
    right: calc(50% - 812px);
  }
}

.banner__background-wrapper {
  background-image: var(--banner-bg-image-mobile);
  background-repeat: no-repeat repeat;
  background-size: 100%;
  height: 55%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@media only screen and (max-width: 550px) {
  .banner__background-wrapper {
    height: 296px;
  }
}
@media only screen and (min-width: 901px) {
  .banner__background-wrapper {
    background-image: var(--banner-bg-image);
    height: 100%;
    left: 50%;
    max-width: 720px;
    width: 50%;
  }
}

.banner__background-wrapper--no-bg {
  background-image: none;
}

.banner__content {
  margin: 0 auto;
  position: relative;
}
@media only screen and (min-width: 901px) {
  .banner__content {
    align-items: flex-end;
    display: flex;
    flex-direction: row-reverse;
    max-width: 1200px;
    padding: 0 40px;
  }
}

.banner__content--no-img {
  padding-top: 30px;
}
@media only screen and (min-width: 901px) {
  .banner__content--no-img {
    padding-top: 0;
  }
}

@media only screen and (min-width: 901px) {
  .banner__text-content,
  .banner__image {
    flex: 1 1 50%;
    max-width: 50%;
  }
}

.banner__text-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 40px;
}
@media only screen and (min-width: 901px) {
  .banner__text-content {
    display: block;
    margin-right: auto;
    padding: 80px 20px 100px 0;
  }
}
@media only screen and (max-width: 359px) {
  .banner__text-content {
    padding: 0 14px;
  }
}

.banner__image {
  display: flex;
  justify-content: center;
  max-width: 100%;
  position: relative;
}
.banner__image img {
  display: block;
  max-width: 100%;
}

.banner__title {
  color: #fff;
  font-size: 0;
  line-height: 1;
  margin-bottom: 15px;
  text-align: center;
}
@media only screen and (min-width: 901px) {
  .banner__title {
    margin-bottom: 20px;
    text-align: left;
  }
}

.banner__title--main,
.banner__title--secondary {
  color: #fff;
  display: block;
  font-size: 24px;
}
@media only screen and (min-width: 1400px) {
  .banner__title--main,
  .banner__title--secondary {
    font-size: 48px;
  }
}
@media only screen and (min-width: 1025px) {
  .banner__title--main,
  .banner__title--secondary {
    font-size: 40px;
  }
}
@media only screen and (max-width: 1024px) {
  .banner__title--main,
  .banner__title--secondary {
    font-size: 32px;
  }
}
@media only screen and (max-width: 767px) {
  .banner__title--main,
  .banner__title--secondary {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1400px) {
  .banner__title--main {
    -webkit-text-stroke: 1px #fff;
    color: transparent;
    margin-bottom: 0;
  }
}

.banner__title--money {
  font-size: 48px;
}
@media only screen and (min-width: 1025px) {
  .banner__title--money {
    font-size: 60px;
  }
}
@media only screen and (max-width: 900px) {
  .banner__title--money {
    margin-top: 8px;
  }
}

.banner__title--money-subtext {
  font-size: 14px;
}
@media only screen and (min-width: 901px) {
  .banner__title--money-subtext {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1400px) {
  .banner__title--money-subtext {
    margin-left: 30px;
  }
}

.button-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
@media only screen and (min-width: 901px) {
  .button-wrapper {
    flex-direction: row;
  }
}

.banner__link {
  margin-bottom: 20px;
  transition: filter 0.2s ease-in-out;
}
.banner__link:has(+ .banner__link-extra) {
  margin-bottom: 16px;
}
@media only screen and (min-width: 901px) {
  .banner__link:has(+ .banner__link-extra) {
    margin-right: 16px;
  }
}
.banner__link:hover {
  filter: brightness(1.13);
}
@media only screen and (min-width: 901px) {
  .banner__link {
    margin-bottom: 30px;
  }
}

.banner__link-extra {
  margin-bottom: 20px;
  color: #fff;
  border-color: #fff;
}
.banner__link-extra:hover {
  color: #6131ad;
  border-color: #6131ad;
}
@media only screen and (min-width: 901px) {
  .banner__link-extra {
    margin-bottom: 16px;
  }
}

.banner__disclaimer {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}
.banner__disclaimer a {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  text-transform: inherit;
  text-decoration: underline;
}
.banner__disclaimer a:hover {
  text-decoration: none;
}