.banner {
    --date-color: #2d2d2d;

    position: relative;

    .countdownLocalized__countdown-wrap {
        background-color: $spray;
        margin-bottom: 15px;
        padding: 10px;
        position: relative;

        @include mq($not-mobile-tablet-pt) {
            margin-bottom: 20px;
        }
    }

    .countdownLocalized__value,
    .countdownLocalized__label {
        color: var(--date-color);
    }

    .countdownLocalized__label::after {
        background-color: var(--date-color);
    }

    .countdownLocalized__icon::before {
        @include iconfont-styles;

        background-image: none;
        color: var(--date-color);
        content: svg(clock-white);
        font-size: 15px;
        line-height: 1;

        @include mq($not-mobile-tablet-pt) {
            font-size: 20px;
        }
    }

    &:not(.banner--no-lineplay)::after { // lineplay styling
        background-image: url("../../../../img/svg/lineplay-banner.svg");
        background-position: right top;
        background-repeat: no-repeat;
        background-size: contain;
        bottom: -650px;
        content: "";
        height: 667px;
        position: absolute;
        right: calc(50% - 685px);
        width: 1920px;
        z-index: -1;

        @include mq ($mobile-pt) {
            bottom: -700px;
            right: calc(50% - 556px);
        }

        @include mq($not-mobile-tablet-pt) {
            bottom: -590px;
            height: 950px;
            right: calc(50% - 812px);
        }
    }
}

.banner__background-wrapper {
    background-image: var(--banner-bg-image-mobile);
    background-repeat: no-repeat repeat;
    background-size: 100%;
    height: 55%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    @include mq($mobile-pt-l) {
        height: 296px;
    }

    @include mq($not-mobile-tablet-pt) {
        background-image: var(--banner-bg-image);
        height: 100%;
        left: 50%;
        max-width: 720px;
        width: 50%;
    }
}

.banner__background-wrapper--no-bg {
    background-image: none;
}

.banner__content {
    margin: 0 auto;
    position: relative;

    @include mq($not-mobile-tablet-pt) {
        align-items: flex-end;
        display: flex;
        flex-direction: row-reverse;
        max-width: 1200px;
        padding: 0 40px;
    }
}

.banner__content--no-img {
    padding-top: 30px;

    @include mq($not-mobile-tablet-pt) {
        padding-top: 0;
    }
}

.banner__text-content,
.banner__image {
    @include mq($not-mobile-tablet-pt) {
        flex: 1 1 50%;
        max-width: 50%;
    }
}

.banner__text-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 40px;

    @include mq($not-mobile-tablet-pt) {
        display: block;
        margin-right: auto;
        padding: 80px 20px 100px 0;
    }

    @include mq($mobile-xs) {
        padding: 0 14px;
    }
}

.banner__image {
    display: flex;
    justify-content: center;
    max-width: 100%;
    position: relative;

    img {
        display: block;
        max-width: 100%;
    }
}

.banner__title {
    color: $white;
    font-size: 0; // removes space between inline spans
    line-height: 1;
    margin-bottom: 15px;
    text-align: center;

    @include mq($not-mobile-tablet-pt) {
        margin-bottom: 20px;
        text-align: left;
    }
}

.banner__title--main,
.banner__title--secondary {
    color: $white;
    display: block;
    font-size: 24px;

    @include mq($desktop-xxl) {
        font-size: 48px;
    }

    @include mq($not-mobile-tablet) {
        font-size: 40px;
    }

    @include mq($tablet-max) {
        font-size: 32px;
    }

    @include mq($mobile-max) {
        font-size: 24px;
    }
}

.banner__title--main {
    @include mq($desktop-xxl) {
        -webkit-text-stroke: 1px $white;
        color: transparent;
        margin-bottom: 0;
    }
}

.banner__title--money {
    font-size: 48px;

    @include mq($not-mobile-tablet) {
        font-size: 60px;
    }

    @include mq($mobile-tablet-pt) {
        margin-top: 8px;
    }
}

.banner__title--money-subtext {
    font-size: 14px;

    @include mq($not-mobile-tablet-pt) {
        font-size: 20px;
    }

    @include mq($desktop-xxl) {
        margin-left: 30px;
    }
}

.button-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    align-items: center;

    @include mq($not-mobile-tablet-pt) {
        flex-direction: row;
    }
}

.banner__link {
    margin-bottom: 20px;
    transition: filter 0.2s ease-in-out;

    &:has(+ .banner__link-extra) {
        margin-bottom: 16px;

        @include mq($not-mobile-tablet-pt) {
            margin-right: 16px;
        }
    }

    &:hover {
        filter: brightness(1.13);
    }

    @include mq($not-mobile-tablet-pt) {
        margin-bottom: 30px;
    }
}

.banner__link-extra {
    margin-bottom: 20px;
    color: $white;
    border-color: $white;

    &:hover {
        color: $purple-heart;
        border-color: $purple-heart;
    }

    @include mq($not-mobile-tablet-pt) {
        margin-bottom: 16px;
    }
}

.banner__disclaimer {
    color: $white;
    font-size: 14px;
    line-height: 20px;
    margin: 0;

    a {
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        text-transform: inherit;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}
